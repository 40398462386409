<template>
    <div id="about" class="flex flex-nowrap">
        <div class="max-lg:hidden relative about-banner w-1/5 flex flex-col py-4">
            <img class="absolute w-48 top-[5%] left-44" src="./../../assets/about_pic1.png" alt="">
            <img class="absolute w-48 top-[25%] left-60" src="./../../assets/about_pic2.png" alt="">
            <img class="absolute w-48 top-[45%] left-44" src="./../../assets/about_pic3.png" alt="">
            <img class="absolute w-48 top-[65%] left-64" src="./../../assets/about_pic4.png" alt="">
        </div>
        <div class="about-bg w-4/5 h-full flex flex-col gap-5 p-44 pl-60 max-lg:p-5 max-lg:w-full">
            <h5 class="text-Primary font-semibold text-xl">About Us</h5>

            <h4 class="font-bold text-2xl">Healthy Snacks For Healthy Life</h4>

            <p>At our shop, we take pride in offering a delightful assortment of dates and nuts to satisfy your cravings and provide you with nourishing snacks. We believe in the power of natural and wholesome foods to enhance your well-being.</p>

            <p>Our collection of dates includes a variety of delectable options, from soft and juicy Medjool dates to the sweet and caramel-like flavors of Deglet Noor dates. These luscious fruits are known for their natural sweetness, satisfying texture, and numerous health benefits.</p>

            <p>In addition to dates, we also bring you a wide selection of premium nuts. Whether you prefer the buttery richness of almonds, the crunchiness of pistachios, or the earthy goodness of walnuts, our shop offers an array of high-quality nuts to suit every taste.</p>

            <p>We carefully source our products from trusted suppliers who share our commitment to quality. Each date and nut is handpicked, ensuring freshness, flavor, and superior quality in every bite... Our friendly and knowledgeable team is here to assist you in finding the perfect combination of dates and nuts to suit your preferences.</p>
        </div>
    </div>
  
</template>

<script setup>


</script>

<style>

</style>