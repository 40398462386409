import { ref } from "vue";

const scrollActive = ref(false)

export function useToggleScroll() {
    
    const toggle = () => {scrollActive.value = !scrollActive.value}
    const getScrollActive = () => scrollActive.value

    return {
        toggle,
        getScrollActive
    }
}