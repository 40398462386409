<template>
  <div :class="{
    'slideIn': isMobileOpen,
    'slideOut': !isMobileOpen
    }" class="mobilenav fixed top-0 right-0 w-screen h-screen bg-white z-[51] flex-col justify-start items-center p-10 pl-14 gap-14">
    <div class="flex flex-nowrap w-full justify-between">
      <div class="text-Primary font-extrabold text-xl">Dates Fruit PH</div>
      <button class="" @click="toggleMobileContainer">
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/><path fill="currentColor" d="m12 14.122l5.303 5.303a1.5 1.5 0 0 0 2.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 1 0-2.122-2.121L12 9.879L6.697 4.576a1.5 1.5 0 1 0-2.122 2.12L9.88 12l-5.304 5.304a1.5 1.5 0 1 0 2.122 2.12z"/></g></svg>
      </button>
    </div>
    <div class="flex flex-col items-start w-full gap-10">
      <a @click="() => goTo('overview')" class="hover:cursor-pointer font-semibold hover:translate-x-2 hover:text-Primary duration-200">Home</a>
      <a @click="() => goTo('about')" class="hover:cursor-pointer font-semibold hover:translate-x-2 hover:text-Primary duration-200">About Us</a>
      <a @click="() => goTo('products')" class="hover:cursor-pointer font-semibold hover:translate-x-2 hover:text-Primary duration-200">Products</a>
      <a @click="() => goTo('occasion')" class="hover:cursor-pointer font-semibold hover:translate-x-2 hover:text-Primary duration-200">Occasions Gifting</a>
      <a @click="() => goTo('contact')" class="hover:cursor-pointer font-semibold hover:translate-x-2 hover:text-Primary duration-200">Contact Us</a>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

const emit = defineEmits()
const props = defineProps({
    isMobileOpen: {
        type: Boolean,
    }
})

const goTo = (id) =>{
  let topPosition = document.getElementById(id).offsetTop - 100;
  if(id == 'overview') topPosition -= 130;
  window.scrollTo({
      top: topPosition,
      left: 0,
      behavior: 'smooth',
  }); 
  emit('toggledMobileContainer')
}

const toggleMobileContainer = () => {
    emit('toggledMobileContainer')
}
</script>

<!-- DETECT KUNG ANO STATUS NG MOBILEOPEN IF NAKASARADO NA TANGGALIN SLIDEIN TAS IPASOK SLIDEOUT USING :CLASS -->