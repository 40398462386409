<template>
    <div @click="toggleMobileContainer" class="
    hover:cursor-pointer
    h-10
    flex
    items-center
    justify-center
    py-10">
        <div class="burger">  </div>
    </div>

</template>

<script setup>
import { defineEmits } from 'vue';
const emit = defineEmits()

const toggleMobileContainer = () => {
    emit('toggledMobileContainer')
}
</script>

<style>

</style>