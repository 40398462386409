<template>
  <div id="overview" class="mt-20 max-lg:p-0 pl-[5vw] min-h-screen flex flex-row items-center relative">
    <div class="w-full flex max-lg:flex-col-reverse  max-lg:gap-10 max-lg:items-center">
        <div v-motion-slide-visible-once-bottom :delay="700" class="max-lg:px-5 max-lg:mt-10 w-2/3 flex flex-col justify-center gap-5 max-lg:w-fit max-lg:items-center">
            <h1 class="max-lg:text-3xl max-lg:text-center font-bold text-5xl">A <span class="text-Primary">Delectable</span> and <br> <span  class="text-Primary">Nutritious Fruit</span></h1>
            <p class="max-w-[500px] max-lg:text-center">
                Discover the Exquisite Delights and Abundant Nutritional
                Benefits of Dates: Nature's Sweet and Nourishing Fruit.
            </p>
            <button
            @click="goTo"
            class="
            hover:-translate-y-1
            duration-200
            w-40 flex 
            flex-row 
            flex-nowrap 
            justify-center 
            items-center 
            p-2 
            bg-Primary
            text-white
            font-semibold
            gap-2
            rounded-lg
            mt-10">
                <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24"><path fill="#FFFFFF" d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"/></svg>
                Products
            </button>

            <div class="mt-[10vh] flex flex-row flex-nowrap gap-10 max-lg:flex-col ">
                <div class="duration-200 max-lg:w-full w-1/5 flex flex-row flex-nowrap gap-3 items-center justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 14 14"><path fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" d="M12.88 12.39a1 1 0 0 1-1 1.11H2.12a1 1 0 0 1-1-1.11L2 4.5h10zM4.5 4.5V3a2.5 2.5 0 1 1 5 0v1.5"/></svg>                    
                    <span class="text-gray-500 text-sm font-medium">Select your favourite dates fruit and order</span>
                </div>

                <div class="duration-200 max-lg:w-full w-1/5 flex flex-row flex-nowrap gap-3 items-center justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 32 32"><path fill="gray" d="M16 18a5 5 0 1 1 5-5a5.006 5.006 0 0 1-5 5m0-8a3 3 0 1 0 3 3a3.003 3.003 0 0 0-3-3"/><path fill="gray" d="m16 30l-8.436-9.949a35.076 35.076 0 0 1-.348-.451A10.889 10.889 0 0 1 5 13a11 11 0 0 1 22 0a10.884 10.884 0 0 1-2.215 6.597l-.001.003s-.3.394-.345.447ZM8.813 18.395s.233.308.286.374L16 26.908l6.91-8.15c.044-.055.278-.365.279-.366A8.901 8.901 0 0 0 25 13a9 9 0 1 0-18 0a8.905 8.905 0 0 0 1.813 5.395"/></svg>                    
                    <span class="text-gray-500 text-sm font-medium">Select your receiving place</span>
                </div>

                <div class="duration-200 max-lg:w-full w-1/5 flex flex-row flex-nowrap gap-3 items-center justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 16 16"><path fill="gray" d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2a1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2a1 1 0 0 0 0-2"/></svg>                    
                    <span class="text-gray-500 text-sm font-medium">Get your order within 1-2 hours</span>
                </div>

            </div>
        </div>

        <div class="max-lg:rounded-b-[3rem] lg:min-h-screen bg-Primary w-1/3 max-lg:w-full flex justify-center max-lg:py-10 max-lg:h-64">
            <img draggable="false" class="lg:floating max-lg:top-0 max-lg:right-1/2 max-lg:translate-x-[50%] max-lg:w-[unset] max-lg:h-96  max-sm:top-12 max-sm:h-[20rem] w-[50%] absolute top-5 right-0" src="./../../assets/hero.png" alt="">
        </div>
    </div>
  </div>
</template>

<script setup>

const goTo = () =>{
let topPosition = document.getElementById("products").offsetTop - 70;
window.scrollTo({
    top: topPosition,
    left: 0,
    behavior: 'smooth',
}); 
}

</script>
