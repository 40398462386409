<template>
  <div :class="{
    'filter' : mobileContainerOpen
  }" class="flex flex-col overflow-x-hidden">
    <Navigation @toggleDarkFilter="toggleDarkFilter" />
    <Overview />
    <ProductsDisplay />
    <Occasion />
    <About />
    <Choose />
    <Foot />
  </div>
</template>

<script setup>

import Overview from './overview/Overview.vue'
import Navigation from '@/components/navigation/Navigation.vue'
import ProductsDisplay from './products/ProductsDisplay.vue'
import Occasion from './occasion/Occasion.vue'
import About from './about/About.vue'
import Choose from './choose/Choose.vue'
import Foot from './footer/Footer.vue'
import { ref, defineEmits, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useToggleScroll } from '@/composables/useToggleScroll'

const route = useRoute()
const { toggle, getScrollActive } = useToggleScroll()


onMounted(() => {
  if(route.query.goto == 'products'){
    let topPosition = document.getElementById(route.query.goto).offsetTop - 70;
    window.scrollTo({
        top: topPosition,
        left: 0,
        behavior: 'auto',
    }); 
  }
})

const mobileContainerOpen = ref(false)

const toggleDarkFilter = (status) => {
  mobileContainerOpen.value = Boolean(status)
}

</script>
