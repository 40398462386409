<template>
    <div class="w-64 flex flex-col justify-start aspect-[9/12] rounded-sm shadow-center-lg">
        <div class="bg-white w-full h-3/4 flex items-center justify-center">
            <img class="w-full h-full aspect-square object-cover object-center" :src="productImageLink" :alt="name">
        </div>

        <div class="flex w-full min-h-1/2 p-3 gap-2 items-start">
            <div class="w-1/2 text-balance font-medium">
                {{ limitCharacters(name, 20) }}
            </div>

            <div class="flex flex-col gap-2 w-1/2">
                <router-link v-bind="mode != 'default' ? { target: '_blank' } : {}" :to="{ name: 'product', params: { id }, query: { categoryId } }" class="hover:-translate-y-1 duration-200 border-2 border-neutral-800 rounded-md p-1 text-sm bg-transparent text-nowrap flex items-center justify-center">View Product</router-link>
                <span v-if="priceRange.min != priceRange.max" class="font-semibold text-right">₱{{ Math.round(priceRange.min)  }} - ₱{{ Math.round(priceRange.max) }}</span>
                <span v-else class="font-semibold text-right">₱{{ priceRange.min }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String
    },
    priceRange: {
        type: Object
    },
    id: {
        type: String
    },
    productImageLink: {
        type: String
    },
    categoryId: {
        type: String
    },
    mode: {
        type: String,
        default: 'default'
    }
})

function limitCharacters(inputString, limit) {
    if (inputString.length <= limit) {
        return inputString;
    } else {
        return inputString.substring(0, limit) + "...";
    }
}

</script>

<style>

</style>