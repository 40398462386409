<template>
  <a class="
  hover:bg-Primary 
  hover:text-white 
    hover:cursor-pointer 
    whitespace-nowrap
    rounded-xl 
    duration-200 
    px-5 
    py-2 
    bg-transparent 
    font-medium"
    @click="goTo">
  {{ text }}</a>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    text: {
        type: String,
        default: "Empty",
    },
    linkTo: {
        type: String,
    }
})

const goTo = () =>{
let topPosition = document.getElementById(props.linkTo).offsetTop - 70;
if(props.linkTo == 'overview') topPosition -= 130;
window.scrollTo({
    top: topPosition,
    left: 0,
    behavior: 'smooth',
}); 
}
</script>

<style>

</style>