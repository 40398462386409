<template>
  <div class="flex flex-col items-center gap-10">
    <div class="flex flex-col gap-5 w-4/5 items-center">
        <h4 class="text-2xl font-semibold text-center">Why Should You Try</h4>
        <p class="text-center">
            This Dates is something that provides a primary source of nutrition for the people of the Middle East and North Africa, and there is a belief that the homes that store and eat dates will never go hungry, or lack any nutrition. Filled with nutritious values, the Date fruit is a superfood packaged with taste and health. Every variety of date has its unique benefits and here, you will know all the advantages of eating dates.
        </p>
    </div>
    
    <img v-motion-slide-visible-once-bottom :delay="700" src="./../../assets/benefits.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>