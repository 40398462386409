<template>
  <div class="w-full max-w-[300px] mx-auto min-h-fit aspect-[4/6] shadow-center-sm rounded-lg overflow-hidden">
    <div class="w-full h-[50%] bg-white flex items-center justify-center">
      <img
        class="h-full w-full object-cover object-center"
        :src="imageLink"
        :alt="name"
        draggable="false"
      />
    </div>

    <div
      class="max-sm:p-2 w-full h-[50%] flex flex-col gap-5 justify-around items-center p-5"
    >
      <div class="flex flex-col items-center gap-2">
        <h5 class="text-center font-semibold text-lg max-md:text-base">
          {{ limitCharacters(name, 15) }}
        </h5>
        <p class="flex justify-center items-center text-justify max-md:text-sm">
          {{ limitCharacters(desc, 70) }}
        </p>

        
      </div>
      <router-link
        class=" w-fit underline underline-offset-2 text-center max-md:text-sm"
        :to="{ name: 'category', query: { id: categoryId } }"
      >
        Product Collection
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useGetWindowWidth } from "@/composables/useGetWindowWidth.js"

const { windowWidth } = useGetWindowWidth()

const props = defineProps({
  name: {
    type: String,
  },
  desc: {
    type: String,
  },
  categoryId: {
    type: String,
  },
  imageLink: {
    type: String,
  },
});

const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function limitCharacters(inputString, limit) {
  if (inputString.length <= limit) {
    return inputString;
  } else {
    return inputString.substring(0, limit) + "...";
  }
}
</script>

<style></style>
