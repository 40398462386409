<template>
  <router-view />
</template>

<!-- 
  Change/Fix 

  *All resources is fetched once at a single endpoint, faster if 
  data is fetched only when needed (ex. finefetch lang is categories and then pag kinclick chaka
  palang kukunin products ni selected categories)

  *Add lazada/shopee url if applicable per option NOT per product

  *More refinement sa responsiveness
-->