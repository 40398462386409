import { ref } from 'vue'

const mode = ref(window.innerWidth >= 1024 ? "desktop" : window.innerWidth >= 640 ? "tablet" : "mobile")

window.addEventListener("resize", () => {
    mode.value = window.innerWidth >= 1024 ? "desktop" : window.innerWidth >= 640 ? "tablet" : "mobile"
})

export function getMode(){
    return mode
}