import { ref } from "vue";

export function useGetWindowWidth() {
  const windowWidth = ref(window.innerWidth);

  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });

  return { windowWidth };
}
