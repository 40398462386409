<template>
  <div v-if="!loadingData" class="w-full flex flex-col justify-start">
    <CategoryHeader />
    <div
      class="flex flex-nowrap justify-between w-full px-[5vw] py-7 max-lg:flex-col gap-2 max-lg:px-[3vw] max-sm:px-[2vw]"
    >
      <div
        v-if="mode == 'desktop'"
        class="max-w-[300px] w-1/4 flex flex-col gap-5"
      >
        <h2 class="font-semibold text-xl text-Primary">Categories</h2>
        <form
          @submit.prevent="searchProduct"
          class="max-w-64 flex flex-nowrap gap-2 w-full border-2 px-2 py-1 rounded-md"
        >
          <input
            @input="checkInput"
            v-model="searchInput"
            class="focus:outline-none w-full"
            type="text"
            name=""
            id=""
            placeholder="Search products"
          />
          <button class="hover:text-neutral-400 duration-200">
            <svg
              class="text-inherit"
              xmlns="http://www.w3.org/2000/svg"
              width="1.5rem"
              height="1.5rem"
              viewBox="0 0 24 24"
            >
              <path
                class="text-inherit"
                fill="currentColor"
                d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5t1.888-4.612T9.5 3t4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5t-1.312-3.187T9.5 5T6.313 6.313T5 9.5t1.313 3.188T9.5 14"
              />
            </svg>
          </button>
        </form>
        <div class="flex flex-col justify-start items-start gap-5">
          <button
            v-show="categories.length > 0 && !loadingData"
            :class="{ 'text-Primary': selectedCategoryId == 'all' }"
            class="hover:text-Primary hover:translate-x-2 duration-200 font-medium text-left"
            @click="() => updateCategory()"
          >
            All
          </button>
          <button
            :class="{ 'text-Primary': selectedCategoryId == category._id }"
            class="hover:text-Primary hover:translate-x-2 duration-200 font-medium text-left"
            v-for="category in categories"
            :key="category._id"
            @click="() => updateCategory(category._id)"
          >
            {{ limitCharacters(category.name, 30) }}
          </button>
          <div v-if="categories.length == 0 && !loadingData">
            No Categories.
          </div>
        </div>
      </div>
      <div v-else class="max-md:px-0 rounded-md flex flex-col gap-5 px-[2vw]">
        <h2 class="font-semibold text-xl text-Primary">Categories</h2>
        <form
          @submit.prevent="searchProduct"
          class="max-sm:max-w-none max-w-64 flex flex-nowrap gap-2 w-full border-2 px-2 py-1 rounded-md"
        >
          <input
            v-model="searchInput"
            class="focus:outline-none w-full"
            type="text"
            name=""
            id=""
            placeholder="Search product"
          />
          <button class="hover:text-neutral-400 duration-200">
            <svg
              class="text-inherit"
              xmlns="http://www.w3.org/2000/svg"
              width="1.5rem"
              height="1.5rem"
              viewBox="0 0 24 24"
            >
              <path
                class="text-inherit"
                fill="currentColor"
                d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5t1.888-4.612T9.5 3t4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5t-1.312-3.187T9.5 5T6.313 6.313T5 9.5t1.313 3.188T9.5 14"
              />
            </svg>
          </button>
        </form>
        <select
          v-model="selectedCategoryId"
          @change="() => updateCategory(selectedCategoryId)"
          class="max-md:max-w-none px-2 py-1 focus:outline-none bg-transparent w-full border-none border-b-4 font-medium max-w-[400px] text-lg"
          name="category"
          id="categoryInput"
        >
          <option value="all">All</option>
          <option
            v-for="category in categories"
            :key="category.id"
            :value="category._id"
          >
            {{ category.name }}
          </option>
        </select>
      </div>
      <div
        v-if="selectedCategoryId"
        class="w-3/4 max-lg:w-full flex flex-col gap-10"
      >
        <div
          v-if="selectedCategoryId != 'all'"
          class="flex flex-col gap-2 px-[2vw]"
        >
          <div class="max-lg:hidden font-semibold text-xl">
            {{ getCategoryName() }}
          </div>
          <div class="">
            <span>{{
              isFullDesc ? getCategoryDesc() : limitShow(getCategoryDesc())
            }}</span>
            <span>&nbsp;</span>
            <button
              ref="seeMoreBtn"
              @click="toggleFullDescription"
              class="hover: duration-200 font-semibold"
              v-if="getCategoryDesc().length > 200"
            >
              {{ isFullDesc ? "See less" : "See more" }}
            </button>
          </div>
        </div>

        <div
          v-if="selectedProducts.length > 0"
          class="product-grid gap-y-20 max-sm:gap-y-10 place-items-center"
        >
          <ProductCard
            v-for="product in selectedProducts"
            :productImageLink="product.variants[0]?.images[0]?.productImgLink"
            :key="product.id"
            :id="product._id"
            :name="product.name"
            :categoryId="selectedCategoryId"
            :priceRange="getProductPriceRange(product.variants)"
          />
        </div>

        <span
          v-if="selectedProducts.length == 0"
          class="w-full h-full flex justify-center items-center"
          >No products.</span
        >
      </div>
    </div>
  </div>

  <div
    v-if="loadingData && delayFinished"
    class="flex items-center justify-center flex-col h-screen"
  >
    <svg
      class="animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      width="3rem"
      height="3rem"
      viewBox="0 0 24 24"
    >
      <defs>
        <linearGradient
          id="mingcuteLoadingFill0"
          x1="50%"
          x2="50%"
          y1="5.271%"
          y2="91.793%"
        >
          <stop offset="0%" stop-color="#FFB908" />
          <stop offset="100%" stop-color="#FFB908" stop-opacity="0.55" />
        </linearGradient>
        <linearGradient
          id="mingcuteLoadingFill1"
          x1="50%"
          x2="50%"
          y1="15.24%"
          y2="87.15%"
        >
          <stop offset="0%" stop-color="#FFB908" stop-opacity="0" />
          <stop offset="100%" stop-color="#FFB908" stop-opacity="0.55" />
        </linearGradient>
      </defs>
      <g fill="none">
        <path
          d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
        />
        <path
          fill="url(#mingcuteLoadingFill0)"
          d="M8.749.021a1.5 1.5 0 0 1 .497 2.958A7.502 7.502 0 0 0 3 10.375a7.5 7.5 0 0 0 7.5 7.5v3c-5.799 0-10.5-4.7-10.5-10.5C0 5.23 3.726.865 8.749.021"
          transform="translate(1.5 1.625)"
        />
        <path
          fill="url(#mingcuteLoadingFill1)"
          d="M15.392 2.673a1.5 1.5 0 0 1 2.119-.115A10.475 10.475 0 0 1 21 10.375c0 5.8-4.701 10.5-10.5 10.5v-3a7.5 7.5 0 0 0 5.007-13.084a1.5 1.5 0 0 1-.115-2.118"
          transform="translate(1.5 1.625)"
        />
      </g>
    </svg>
    <div class="text-Primary text-lg font-medium px-5 text-center">
      Retrieving categories...
    </div>
  </div>
</template>

<script setup>
import CategoryHeader from "./CategoryHeader.vue";
import ProductCard from "./ProductCard.vue";
import { ref, onMounted, watchEffect, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getMode } from "./../../composables/getMode";
import { fetchData } from "@/composables/fetchData";

const route = useRoute();
const router = useRouter();

const selectedCategoryId = ref();
const selectedProducts = ref([]);

const isFullDesc = ref(false);

const searchInput = ref("");

const mode = getMode();

const { categories, loadingData, getData, delayFinished } = fetchData(() => {
  categories.value = categories.value.filter(
    (category) => category.status == "Posted"
  );

  if (route.query.id) updateCategory(route.query.id);
  else updateCategory();
});

onMounted(async () => {
  await getData();
  scrollToTop();
});

function updateCategory(id = "all") {
  isFullDesc.value = false;
  if (id != "all") {
    let categoryExist = false;

    categories.value.forEach((category) => {
      if (category._id == id) categoryExist = true;
    });

    if (!categoryExist) {
      updateCategory();
      return;
    }

    selectedCategoryId.value = id;
    selectedProducts.value = filterProducts(id);
    router.replace({
      path: "/category",
      query: { id },
    });
  } else if (id == "all") {
    selectedCategoryId.value = id;
    selectedProducts.value = getAllProducts();
    router.replace({
      path: "/category",
      query: { id },
    });
  }
}

function toggleFullDescription() {
  isFullDesc.value = !isFullDesc.value;
}

function filterProducts(id) {
  const category = categories.value.find((category) => category._id == id);

  if (category)
    return category.products.filter((product) => product.status != "Drafted");
  else return [];
}

function getAllProducts() {
  const allProducts = [];
  categories.value.forEach((category) => {
    if (category.products) {
      category.products.forEach((product) => {
        if (product.status != "Drafted") allProducts.push(product);
      });
    }
  });

  return allProducts;
}

function getCategoryName() {
  const selected = categories.value.find(
    (category) => category._id == selectedCategoryId.value
  );
  return selected.name;
}

function getCategoryDesc() {
  const selected = categories.value.find(
    (category) => category._id == selectedCategoryId.value
  );
  return selected.description;
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Smooth scrolling animation
  });
}

function limitCharacters(inputString, limit) {
  if (inputString.length <= limit) {
    return inputString;
  } else {
    return inputString.substring(0, limit) + "...";
  }
}

function getProductPriceRange(variation = []) {
  let priceArr = [];

  variation.forEach((item) => {
    priceArr.push(item.price);
  });

  let min = Math.min(...priceArr);
  let max = Math.max(...priceArr);

  return {
    min,
    max,
  };
}

function limitShow(content) {
  if (content.length > 200) return content.substring(0, 200) + "...";
  else return content;
}

function searchProduct() {
  updateCategory();

  const tempProducts = selectedProducts.value.filter((product) =>
    product.name.toLowerCase().includes(searchInput.value.toLowerCase())
  );

  selectedProducts.value = [...tempProducts];

  // Filter selectedProducts to only those with matching names

  // searchInput.value = ""
}

function checkInput() {
  if (searchInput.value.length != 0) return;

  updateCategory();
}
</script>
