import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Category from '../views/products/Category.vue'
import Product from '../views/products/Product.vue'
import PageNotFound from '@/views/error/PageNotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/category',
    name: 'category',
    component: Category
  },
  {
    path: '/product/:id',
    name: 'product',
    component: Product
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'pagenotfound',
    component: PageNotFound 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
