<template>
  <div class="flex flex-col gap-10">
    <div class="w-full flex items-center justify-center">
        <h4 class="text-2xl font-semibold text-center">"Give the best gift for <span class="text-red-500">family and friends</span>"</h4>
    </div>
    <div class="w-full grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 justify-center items-start gap-10">
        <!-- <GiftItem id="5" @showGalleryClicked="showGalleryHandler" title="New Year's Gift" desc="Start our new DATES in calendar and cheers to another year of life" :src="5" /> -->
        <template v-if="gifts && !loadingData">
          <GiftItem @showGalleryClicked="showGalleryHandler" v-for="gift in gifts.gifts" :key="gift._id" :gallery="gift.imgURL" :title="gift.title" :desc="gift.description" :image="gift.imgURL[0]" />
          <!-- <div v-for="gift in gifts.gifts" :key="gift._id"> {{ gift.imgURL[0] }}</div> -->
        </template>
    </div>

    <div v-if="loadingData" class="w-full flex justify-center items-center animate-pulse text-center">Loading...</div>

  </div>
  <div v-if="showGallery" class="overflow-y-auto flex flex-col w-screen h-screen gap-10 fixed top-0 left-0 bg-[rgba(0,0,0,0.8)] z-[1000] py-10 px-20 max-lg:px-10">
    <div class="p-2 sticky top-0 left-0 w-full flex items-center justify-between ">
        <span class="text-white text-3xl p-2">Gallery</span>
        <button @click="closeGallery" class="z-50 hover:bg-neutral-400 duration-200 rounded-full p-2 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/><path fill="white" d="m12 14.122l5.303 5.303a1.5 1.5 0 0 0 2.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 1 0-2.122-2.121L12 9.879L6.697 4.576a1.5 1.5 0 1 0-2.122 2.12L9.88 12l-5.304 5.304a1.5 1.5 0 1 0 2.122 2.12z"/></g></svg>
        </button>
    </div>
    <div class="w-full gallery gap-20 h-full place-items-center z-[-1]">
      <a class="hover:scale-110 hover:-translate-y-10 duration-200" v-for="img, index in selectedGalleryGrp" :key="index" :href="img" target="_blank">
        <img class="bg-neutral-900 w-48 rounded-lg aspect-[2/3] object-contain object-center" :src="img" :alt="'image[' + index + ']'">
      </a>
      <div class="w-full h-10"></div>
    </div>
    
  </div>
</template>

<script setup>
import { fetchGift } from '@/composables/fetchGift'
import { onMounted, ref } from 'vue';
import GiftItem from './GiftItem.vue'

const emit = defineEmits('disableScroll')

const selectedGalleryGrp = ref()

const showGallery = ref(false)

const { gifts, loadingData, delayFinished, getData } = fetchGift()

function showGalleryHandler(galeryArr){
  showGallery.value = true

  selectedGalleryGrp.value = galeryArr
  
  document.querySelector('body').style.overflowY = 'hidden';
}

function closeGallery(){
  showGallery.value = !showGallery.value
  document.querySelector('body').style.overflowY = 'auto';
}

onMounted(async () => {
  await getData()

  console.log(gifts.value)
})

</script>

<style>

</style>