import { ref } from "vue";

const categories = ref([]);
const loadingData = ref(true);
const delayFinished = ref(false);

export function fetchData(callback) {
  const getData = async () => {
    if (categories.value.length > 0) {
      if (callback) callback();
      return;
    }

    try {
      // Delay for testing
      // await new Promise(resolve => setTimeout(resolve, 1000));

      //https://datesfruits-4a772f640e8a.herokuapp.com/categories
      // https://clarence-coronel.github.io/datesfruit-user-api/data.json

      // add delay avoid showing loading if instant naman loading
      setTimeout(() => {
        delayFinished.value = true;
      }, 200);

      loadingData.value = true;
      const response = await fetch(process.env.VUE_APP_API_URL);
      const data = await response.json();
      categories.value = data;

      if (callback) {
        callback();
      }

      // console.log(categories.value)
    } catch (err) {
      console.error("Error fetching data:", err.message);
    } finally {
      loadingData.value = false;
    }
  };

  return {
    categories,
    loadingData,
    getData,
    delayFinished,
  };
}
