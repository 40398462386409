<template>
  <div :class="{'hidden': invisible }" class="max-md:border-t-0 min-h-40 flex flex-col w-full gap-10 border-t border-neutral-300 py-10 max-w-[1200px]">
      <div class="w-full text-center text-xl font-semibold">You May Also View</div>
      <div v-if="products" class="px-[5vw] grid place-items-center place-content-center suggestedContainer gap-5">
          <ProductCard v-for="product, index in products" :key="index" :name="product.name" mode="suggested" :id="product._id" :productImageLink="product.variants[0].images[0].productImgLink" :categoryId="categoryId" :priceRange="getProductPriceRange(product.variants)" />
      </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import ProductCard from './ProductCard.vue'
import { fetchData } from '@/composables/fetchData';

const products = ref()
const invisible = ref(false)
const emits = defineEmits(['suggestedLoaded'])
const { categories, loadingData, getData, delayFinished } = fetchData(() => {

  if(props.categoryId !== 'all'){
    products.value = selectRandomItems(removeItself(categories.value.find(category => category._id == props.categoryId).products.filter(product => product.status != 'Drafted')))
    // console.log(props.categories)
  }
  else{
    const tempArr = []
    categories.value.forEach(category=>{
      category.products.forEach(product=>{

          if(product.status != "Drafted"){
            tempArr.push(product)
          }
      })
    })
    products.value = selectRandomItems(removeItself(tempArr))
  }

  if(products.value.length == 0) invisible.value = true

  emits('suggestedLoaded')
})

const props = defineProps({
    categoryId: {
        type: String
    },
    currentSelectedProduct: {
        type: String
    },
    categories:{
      type: Object
    }
})

onMounted(async () => {
 await getData()
})


function selectRandomItems(array) {
  if (array.length <= 3) {
    return array.slice();
  }

  const selectedItems = [];
  const arrayCopy = array.slice();

  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * arrayCopy.length);
    const selectedItem = arrayCopy.splice(randomIndex, 1)[0];
    selectedItems.push(selectedItem);
  }

  return selectedItems;
}

function removeItself(products){
    return products.filter(product => product._id != props.currentSelectedProduct)
}

function getProductPriceRange(variation = []){
  let priceArr = []
  
  variation.forEach(item => {
    priceArr.push(item.price)
  })

  let min = Math.min(...priceArr)
  let max = Math.max(...priceArr)

  return {
    min,
    max
  }
}
</script>
