<template>
    <div class="flex p-5 max-lg:flex-col-reverse max-lg:items-center gap-5" >
        <div v-motion-slide-visible-once-right :delay="700" class="max-lg:w-full w-1/2 flex flex-col gap-5 justify-center">
            <h4 class="text-2xl font-semibold">The Perfect <span class="text-Primary">Gift</span> for Every <span class="text-Primary">Occasion</span></h4>
            <p class="">
                What makes a celebration so special? They’re a chance to come together with the ones you love, connect, and enjoy life. That’s why we especially love making any occasion memorable with CELEBRATIONS dates.
            </p>
            <p>
                Whether you’re gathered around the Christmas tree, singing happy birthday, telling dad how much you love him or just simply saying thank you, the moment becomes a bit sweeter with a bowl of CELEBRATIONS. Because life is better when we celebrate together so let’s have a wonderful DATES.
            </p>
        </div>
    <div v-motion-slide-visible-once-left :delay="700" class="max-lg:w-full w-1/2">
        <img src="./../../assets/occasion-item1.png" alt="">
    </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>