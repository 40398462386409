<template>
  <nav class="shadow-md w-full max-lg:h-24 h-20 flex px-[5vw] items-center justify-between fixed top-0 z-40 bg-white max-lg:px-5">
    <div class="relative max-lg:bg-white bg-Primary w-40 h-full mb-auto max-lg:w-fit flex items-center justify-center">
      
        <img draggable="false" class="max-lg:translate-x-0 max-lg:static max-lg:w-20 w-32 absolute left-1/2 -translate-x-1/2 top-1" src="./../../assets/Dates_Fruit_PH.webp" alt="">
    </div>

    <div v-if="isDesktopMode" class="flex flex-nowrap gap-5 py-4">
        <NavigationItem linkTo="overview" text="Home" />
        <NavigationItem linkTo="about" text="About Us" />
        <NavigationItem linkTo="products" text="Products" />
        <NavigationItem linkTo="occasion" text="Occasions Gifting" />
        <NavigationItem linkTo="contact" text="Contact Us" />
    </div>
    
    <Burger @toggledMobileContainer="toggleMobCon" v-else />
  </nav>
  <MobileContainer :isMobileOpen="isMobileOpen" @toggledMobileContainer="toggleMobCon"/>

</template>

<script setup>
import NavigationItem from './NavigationItem.vue'
import MobileContainer from './MobileContainer.vue'
import Burger from './Burger.vue'
import { ref, defineEmits } from 'vue'

const emit = defineEmits()
const isDesktopMode = ref(window.innerWidth >= 1024 ? true : false)
const isMobileOpen = ref(false)

window.addEventListener("resize", function () {
    isDesktopMode.value = window.innerWidth >= 1024;
    if(isDesktopMode.value) {
      isMobileOpen.value = false
      emit('toggleDarkFilter', isMobileOpen.value)
    }
});

const toggleMobCon = () => {
  isMobileOpen.value = !isMobileOpen.value
  emit('toggleDarkFilter', isMobileOpen.value)
}


</script>

<style>

</style>
