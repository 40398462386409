<template>
  <div class="flex flex-col items-center w-64 gap-5 mx-auto">
    <div class="flex flex-col justify-center items-center gap-2">
        <h3 class="font-Satisfy text-4xl text-red-500 text-center">{{ title }}</h3>
        <span class="text-center">"{{ desc }}"</span>
    </div>

    <button @click="clickHandler" class="shadow-lg rounded-2xl hover:border-2    border-Primary  hover:-translate-y-1 hover:scale-105 duration-200 relative  w-64 h-64 ">
        <img class="rounded-2xl object-cover object-center w-full h-full relative " :src="image" alt="">
    </button>    
</div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String
    },
    desc: {
        type: String
    },
    image: {
        type: Number
    },
    gallery: {
        type: Array
    }
})

const emit = defineEmits(['showGalleryClicked']);

function clickHandler(){
    emit('showGalleryClicked', props.gallery)

}

</script>

<style>

</style>