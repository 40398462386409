import { ref } from "vue";

const gifts = ref([])
const loadingData = ref(true)
const delayFinished = ref(false)

export function fetchGift() {
    const url = "https://dates-fruits-api-production.up.railway.app/gifts"

    const getData = async () => {
        if(gifts.value.length > 0) {
            if(callback) callback()
            return
        }
        
        try {
            // Delay for testing
            // await new Promise(resolve => setTimeout(resolve, 1000));
    
                // add delay avoid showing loading if instant naman loading
            setTimeout(()=>{
                delayFinished.value = true
            }, 200)
    
            loadingData.value = true
            const response = await fetch(url);
            const data = await response.json();
            gifts.value = data;
    
            if(callback){
                callback()
            }
    
        } catch (err) {
            console.error("Error fetching data:", err.message);
        } finally {
            loadingData.value = false;
        }
    };

    return {
        gifts, loadingData, delayFinished, getData
    }
    
}
