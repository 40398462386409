<template>
  <div class="w-full flex flex-col justify-between gap-10 items-center max-md:gap-5">
    <div
      v-if="!loadingData && productData && suggestedLoaded"
      class="w-full flex flex-col justify-between"
    >
      <div class="w-full flex justify-end py-5 items-center sticky top-0 bg-white px-[5vw] shadow-md">
        <router-link
          :to="{ name: 'category', query: { id: route.query.categoryId } }"
          class="hover:bg-neutral-100 duration-200 rounded-full p-2"
        >
          <svg
          class="text-neutral-400"
          xmlns="http://www.w3.org/2000/svg"
          width="1.5rem"
          height="1.5rem"
          viewBox="0 0 24 24"
          
        >
          <g class="text-inherit" fill="none" fill-rule="evenodd">
            <path
              d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
            />
            <path
              class="text-inherit"
              fill="currentColor"
              d="m12 14.122l5.303 5.303a1.5 1.5 0 0 0 2.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 1 0-2.122-2.121L12 9.879L6.697 4.576a1.5 1.5 0 1 0-2.122 2.12L9.88 12l-5.304 5.304a1.5 1.5 0 1 0 2.122 2.12z"
            />
          </g>
          </svg>
        </router-link>
      </div>

      <div class="flex flex-nowrap max-sm:flex-col max-w-[1400px] mx-auto px-[5vw] pt-[5vh]">
        <div class="w-[50%] flex flex-col gap-2 max-sm:w-full px-10 max-md:px-[2vw]">
          <div
            class="w-full aspect-square flex items-center justify-center border-2 rounded-md shadow-md"
          >
            <img
              class="w-full bg-neutral-100 rounded-md aspect-square object-contain object-center"
              :src="selectedImage"
              :alt="productData ? productData.name + `'s Image` : ''"
            />
          </div>
          <div
            v-if="multipleImg"
            class="w-full grid grid-cols-5 gap-2 place-items-center"
          >
            <button
              @click="selectImage(image)"
              target="_blank"
              class="w-full"
              v-for="(image, index) in selectedVarImgs"
              :key="index"
            >
              <img
                :src="image"
                class="md:hover:border-Primary duration-200 border-2 rounded-md shadow-md w-full object-cover bg-neutral-100 object-center aspect-square"
                :alt="productData ? productData.name + `'s Image` : ''"
              />
        </button>
          </div>
        </div>

        <div
          class="w-[50%] max-md:px-[2vw] max-sm:w-full pb-5 px-10 flex flex-col divide-y divide-neutral-300"
        >
          <div class="flex flex-col gap-7 pb-5">
            <h1 class="font-semibold text-2xl">{{ productData.name }}</h1>

            <div class="flex flex-col gap-2">
              <!-- <h2 class="font-medium text-lg">{{ productData.descTitle ? 'productData.descTitle' : 'descTitle placeholder' }}</h2> -->
              <p class="text-sm">
                <span ref="description">
                  {{ limitShow(productData.description) }}
                </span>
                <span>&nbsp;</span>
                <button
                  ref="seeMoreBtn"
                  @click="toggleFullDescription"
                  class="hover: duration-200 font-semibold"
                  v-if="productData.description.length > 200"
                >
                   See more
                </button>
              </p>
            </div>
          </div>

          <!-- Price -->
          <div
            class="font-semibold text-2xl w-full py-5"
            v-if="selectedVariation"
          >
            ₱{{ selectedVariation.price }}
          </div>

          <!-- Flavor -->
          <div class="py-5 flex flex-col gap-2" v-if="flavors.length != 0">
            <span class="font-semibold block mb-1">Flavor Profile</span>
            <div
              class="flex flex-row flex-nowrap items-center gap-3"
              v-for="flavor in flavors"
              :key="flavor._id"
            >
              <span>{{ flavor.flavour }}:</span>
              <div class="flex flex-nowrap gap-1">
                <template v-for="(i, index) in flavor.max_value" :key="index">
                  <div
                    class="bg-Primary w-3 h-5"
                    v-if="i <= flavor.min_value"
                  ></div>
                  <div class="bg-neutral-200 w-3 h-5" v-else></div>
                </template>
              </div>
            </div>
          </div>

          <!-- Option -->
          <div class="py-5 flex flex-col gap-2">
            <span class="font-semibold block mb-1">Options</span>
            <div class="flex flex-wrap gap-2">
              <button
                ref="variationButtons"
                @click="() => updateSelectedVariation(variant, index)"
                v-for="(variant, index) in productData.variants"
                :key="index"
                :class="{
                  'border-neutral-300': index != 0,
                  'border-Primary': index == 0,
                }"
                class="md:hover:-translate-y-1 duration-200 border-2 rounded-sm flex flex-nowrap p-2 gap-2 items-center"
              >
                <img
                  class="aspect-square w-5"
                  :src="variant.images[0].productImgLink"
                  alt=""
                />
                <span class="text-left">{{ variant.title }}</span>
              </button>
            </div>
          </div>

          <div class="w-full py-5 flex flex-col gap-2">
            <button
              @click="nutritionOpen = !nutritionOpen"
              class="w-full flex flex-nowrap justify-between items-center"
            >
              <span class="font-semibold block mb-1">Nutrition Facts</span>
              <svg
                :class="{ 'rotate-180': nutritionOpen }"
                class="duration-200"
                xmlns="http://www.w3.org/2000/svg"
                width="1rem"
                height="1rem"
                viewBox="0 0 24 24"
              >
                <g fill="none" fill-rule="evenodd">
                  <path
                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
                  />
                  <path
                    fill="currentColor"
                    d="M13.06 16.06a1.5 1.5 0 0 1-2.12 0l-5.658-5.656a1.5 1.5 0 1 1 2.122-2.121L12 12.879l4.596-4.596a1.5 1.5 0 0 1 2.122 2.12l-5.657 5.658Z"
                  />
                </g>
              </svg>
            </button>
            <div v-show="nutritionOpen">
              <table
                class="w-full table-auto border border-collapse border-neutral-500"
              >
                <tbody>
                  <tr
                    v-for="(container, index) in productData.nutritionalFacts[0]
                      .servingperContainer"
                    :key="index"
                    class="font-semibold border border-neutral-500 divide-x divide-neutral-500 text-base"
                  >
                    <td class="w-2/3 p-2 text-left">
                      {{ index == 0 ? "Serving Per Container" : "" }}
                    </td>
                    <td class="w-1/3 p-2 text-center">
                      {{ container.valueWithUnit }}
                    </td>
                  </tr>

                  <tr
                    v-for="(size, index) in productData.nutritionalFacts[0]
                      .servingSize"
                    :key="index"
                    class="font-semibold border border-neutral-500 divide-x divide-neutral-500 text-base"
                  >
                    <td class="w-2/3 p-2 text-left">
                      {{ index == 0 ? "Serving Size" : "" }}
                    </td>
                    <td class="w-1/3 p-2 text-center">
                      {{ size.valueWithUnit }}
                    </td>
                  </tr>

                  <tr
                    class="font-semibold border divide-x divide-neutral-500 border-neutral-500 text-base"
                  >
                    <td class="w-2/3 p-2 text-left">Calories</td>
                    <td class="w-1/3 p-2 text-center">
                      {{ productData.nutritionalFacts[0].calories }}
                    </td>
                  </tr>

                  <template
                    v-for="(nutrition, index) in productData.nutritionalFacts[0]
                      .item"
                    :key="index"
                  >
                    <tr
                      class="border divide-x divide-neutral-500 border-neutral-500"
                    >
                      <td class="w-2/3 p-2 text-left">{{ nutrition.item }}</td>
                      <td class="w-1/3 p-2 text-center">
                        {{ nutrition.valueWithUnit }}
                      </td>
                    </tr>
                    <template v-if="nutrition.subitem.length > 0">
                      <tr
                        class="border divide-x divide-neutral-500 border-neutral-500"
                        v-for="(subNutrition, index) in nutrition.subitem"
                        :key="index"
                      >
                        <td class="w-2/3 p-2 pl-5 text-left">
                          {{ subNutrition.name }}
                        </td>
                        <td class="w-1/3 p-2 text-center">
                          {{ subNutrition.valueWithUnit }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="flex items-center justify-center flex-col h-screen">
      <svg
        class="animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        width="3rem"
        height="3rem"
        viewBox="0 0 24 24"
      >
        <defs>
          <linearGradient
            id="mingcuteLoadingFill0"
            x1="50%"
            x2="50%"
            y1="5.271%"
            y2="91.793%"
          >
            <stop offset="0%" stop-color="#FFB908" />
            <stop offset="100%" stop-color="#FFB908" stop-opacity="0.55" />
          </linearGradient>
          <linearGradient
            id="mingcuteLoadingFill1"
            x1="50%"
            x2="50%"
            y1="15.24%"
            y2="87.15%"
          >
            <stop offset="0%" stop-color="#FFB908" stop-opacity="0" />
            <stop offset="100%" stop-color="#FFB908" stop-opacity="0.55" />
          </linearGradient>
        </defs>
        <g fill="none">
          <path
            d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
          />
          <path
            fill="url(#mingcuteLoadingFill0)"
            d="M8.749.021a1.5 1.5 0 0 1 .497 2.958A7.502 7.502 0 0 0 3 10.375a7.5 7.5 0 0 0 7.5 7.5v3c-5.799 0-10.5-4.7-10.5-10.5C0 5.23 3.726.865 8.749.021"
            transform="translate(1.5 1.625)"
          />
          <path
            fill="url(#mingcuteLoadingFill1)"
            d="M15.392 2.673a1.5 1.5 0 0 1 2.119-.115A10.475 10.475 0 0 1 21 10.375c0 5.8-4.701 10.5-10.5 10.5v-3a7.5 7.5 0 0 0 5.007-13.084a1.5 1.5 0 0 1-.115-2.118"
            transform="translate(1.5 1.625)"
          />
        </g>
      </svg>
      <div class="text-Primary text-lg font-medium px-5 text-center">
        Retrieving product...
      </div>
    </div>

    <Suggested
      v-show="productData && suggestedLoaded"
      @suggestedLoaded="toggleSuggestedLoaded"
      :categoryId="route.query.categoryId"
      :categories="categories"
      :currentSelectedProduct="productId"
    />

    <FooterSection
      v-show="!loadingData && productData && suggestedLoaded"
      :showSubscribe="false"
    />
  </div>
</template>

<script setup>
import Suggested from "./Suggested.vue";
import FooterSection from "./../footer/Footer.vue";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { fetchData } from "@/composables/fetchData";

const route = useRoute();
const router = useRouter();
const productId = ref(route.params.id);
const productData = ref(null);
const selectedVariation = ref();

const selectedImage = ref(null)

const selectImage = (image) => {
  selectedImage.value = image
}

let showDesc = false;
const description = ref();
const seeMoreBtn = ref();

const multipleImg = ref(false);
const selectedVarImgs = ref([]);

const flavors = ref([]);

const nutritionOpen = ref(false);

const variationButtons = ref([]);

const suggestedLoaded = ref(false);

const toggleSuggestedLoaded = () => {
  suggestedLoaded.value = !suggestedLoaded.value;
};

const { categories, loadingData, getData, delayFinished } = fetchData(() => {
  categories.value.forEach((item) => {
    item.products.forEach((product) => {
      if (product._id == productId.value) {
        productData.value = product;

        updateSelectedVariation(productData.value.variants[0], 0);

        productData.value.specification.forEach((flavor) =>
          flavors.value.push(flavor)
        );
        loadingData.value = false;
      }
    });
  });

  if (!productData.value) {
    router.push({ name: "pagenotfound" });
  }
});

onMounted(async () => {
  await getData();
  scrollToTop();
});

const excludedFirstImage = computed(() => {
  const temp = [...selectedVarImgs.value];
  temp.shift();

  return temp.slice(0, 4);
});

function limitShow(content) {
  if (content.length > 200) return content.substring(0, 200) + "...";
  else return content;
}

function updateSelectedVariation(newVariation, index) {
  variationButtons.value.forEach((button) => {
    button.classList.remove("border-Primary");
    button.classList.add("border-neutral-300");
  });

  variationButtons.value.forEach((btn, currentIndex) => {
    if (currentIndex == index) {
      btn.classList.remove("border-neutral-300");
      btn.classList.add("border-Primary");
    }
  });

  selectedVariation.value = newVariation;

  selectedImage.value = selectedVariation.value.images[0].productImgLink


  multipleImg.value = selectedVariation.value.images.length > 1;

  selectedVarImgs.value.length = 0;

  selectedVariation.value.images.forEach((img) =>
    selectedVarImgs.value.push(img.productImgLink)
  );
}

function toggleFullDescription() {
  if (showDesc) {
    description.value.innerText = limitShow(productData.value.description);
    showDesc = !showDesc;
    seeMoreBtn.value.innerText = "See more";
  } else {
    description.value.innerText = productData.value.description;
    showDesc = !showDesc;
    seeMoreBtn.value.innerText = "See less";
  }
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Smooth scrolling animation
  });
};
</script>

<style></style>
